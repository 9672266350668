import React from 'react';
import './App.css';

import blogs from './data/blogs.js';

import Blog from './Blog.js';

import BannerHeader from './BannerHeader.js';
import SoloTexto from './SoloTexto';

import bannerBlog from './images/1x/galdisa_blog_blog_header.png';
import blog1Img from './images/1x/galdisa_blogImg1.jpg';
import blog2Img from './images/1x/galdisa_blogImg2.jpg';
import blog3Img from './images/1x/galdisa_blogImg3.jpg';
import blog4Img from './images/1x/galdisa_blogImg4.jpg';
import blog9 from './images/1x/galdisa_blogImg8.jpg';
import blog10 from './images/1x/galdisa_blogImg9.jpg';
import blog11 from './images/1x/galdisa_blogImg11.jpg';
import blog12 from './images/1x/galdisa_blogImg12.jpg';
import blog14 from './images/1x/galdisa_blogImg14.jpg';
import blog17 from './images/1x/galdisa_blogImg17.jpg';
import blog18 from './images/1x/galdisa_blogImg18.jpg';
import blog19 from './images/1x/galdisa_blogImg19.jpg';
import blog20 from './images/1x/galdisa_blogImg20.jpg';
import blog21 from './images/1x/galdisa_blogImg21.jpg';
import blog22 from './images/1x/galdisa_blogImg22.jpg';
import blog23 from './images/1x/galdisa_blogImg23.jpg';
import blog24 from './images/1x/galdisa_blogImg24.jpg';
import blog25 from './images/1x/galdisa_blogImg25.jpg';
import blog26 from './images/1x/galdisa_blogImg26.jpg';
import blog27 from './images/1x/galdisa_blogImg27.jpg';
import blog28 from './images/1x/galdisa_blogImg28.jpg';
import blog29 from './images/1x/galdisa_blogImg29.jpg';
import blog30 from './images/1x/galdisa_blogImg30.jpg';
import blog31 from './images/1x/galdisa_blogImg31.jpg';
import blog32 from './images/1x/galdisa_blogImg32.jpg';

class BlogPage extends React.Component {

  constructor(props){
    super(props);
    const post = props.match.params.post;
    console.log(props);
    this.state = {
      post: post,
      blogData: blogs[props.selectedLanguage][post]
    }
  }

  
  componentDidUpdate(prevProps){
    const changes = {};


    if(this.state.post !== this.props.match.params.post || prevProps.selectedLanguage !== this.props.selectedLanguage){
      changes.post = this.props.match.params.post;
      changes.blogData = blogs[this.props.selectedLanguage][changes.post];
    }
    if(Object.keys(changes).length > 0){
      this.setState(changes);
    }
  }


  render() {
    let blogBanners={
      es:[{
        imagen: bannerBlog,
        text: <div className="textoHeader" style={{position:'absolute', top:'54%', left:'5%', display:'flex', textAlign:'left', 'flex-direction':'column'}}>
          <span className='texto-xl texto-harabara texto-white texto-bold'>Lo que debes saber</span>
          <span className='texto-m texto-white texto-normal'>Descubre <span className='texto-green texto-bold'>tips, noticias y tendencias</span></span>
          <span className="texto-m texto-normal texto-white">que tenemos preparados para ti</span>
        </div>
      }],
      en:[{
        imagen: bannerBlog,
        text: <div className="textoHeader" style={{position:'absolute', top:'54%', left:'5%', display:'flex', textAlign:'left', 'flex-direction':'column'}}>
          <span className='texto-xl texto-harabara texto-white texto-bold'>Discover</span>
          <span className='texto-m texto-white texto-normal'><span className='texto-white texto-bold'>tips, news and trends</span></span>
        </div>
      }],
    };
  
    return(    
      <div className="Contenedor">
        <BannerHeader selectedLanguage={this.props.selectedLanguage} banners={blogBanners[this.props.selectedLanguage]} className="HeaderPrincipal"/>
        <div id="blog" className="blog">
          <div className="blog-page">
            <div style={{padding:'0 2rem', boxSizing:'border-box', textTransform:'uppercase',fontSize:'2.5rem',width:'100%', margin:'0 auto', textAlign:'center' }} className='texto-green texto-bold'>{this.state.blogData.title}</div>
            {this.state.blogData.pars.map((par,id)=>{
              return <div key={"blog-page-par" + id} className="blog-page-par" >
                {par.gallery && <div className="blog-page-par-gallery">{
                  par.gallery.map((image, idx)=>{return <img key={'blog-page-gallery-' + idx} src={image.src} alt={image.alt} width="100%"/>} )}
                </div>}
                {par.title && <span className="blog-page-par-title">{par.title}</span>}
                {par.text && <span className={"blog-page-par-text texto-normal " + (par.className ? par.className:"") + " texto-m" }>{par.text}</span>}
                {par.list && <ul>{par.list.map((text,idx)=>{return <li key={'blog-page-par-list-' + idx} className="blog-page-par-text texto-normal texto-m">{text}</li>})}</ul>}
              </div>
            })}
            {this.state.blogData.refs.length > 0 &&
              <div key="blog-page-reference" className="blog-page-par"><span className="blog-page-par-title">Referencias:</span><ul>
                {this.state.blogData.refs.map((ref, id)=>{
                  return <li key={"blog-page-par-ref"+id}>
                    <span className="blog-page-reference">{ref.text}</span>
                    {ref.link && <div><span className="blog-page-par-text">Recuperado de: </span><a className="blog-page-ref-link" href={ref.link} rel="noopener noreferrer" target="_blank">{ref.link}</a></div>}    
                    </li>
                })
              }
              </ul></div>
            }
            {this.state.blogData.author &&
              <div className="blog-page-author">
                <span className={"blog-page-par-text texto-s"}>{this.state.blogData.author.brief}</span>
                {this.state.blogData.author.contacts.map((contact)=>{
                  return <div className="texto-s" style={{margin:'0.5em 0'}}><span>{contact.social}:</span><a href={contact.link} rel="noopener noreferrer" target="_blank">{contact.text}</a></div>
                })}
              </div>
            }
          </div>
          <div className="blog-image" style={{backgroundImage:`url(${this.state.blogData.image})`}} >
          </div>
      </div>
      <div id="noticias" style={{width:'100%', margin:'2rem auto'}}>
        <SoloTexto titulo={this.props.selectedLanguage === `es` ? 'ARTÍCULOS RELACIONADOS' : 'RELATED ARTICLES'} color="verde" alineacion="left"  style={{padding:'', width:'calc(100% - 4rem)'}}/>
        <div style={{display:'flex', flexDirection:'row', flexWrap:'wrap', justifyContent:'space-around', width:'100%'}} >
          {Object.keys(blogs[this.props.selectedLanguage]).filter((blogKey) => (this.state.post !== blogKey)).reverse().map((blogKey) => {
              const blogData = blogs[this.props.selectedLanguage][blogKey];
              return <Blog selectedLanguage={this.props.selectedLanguage} post={blogKey} imagen={blogData.image} titulo={blogData.title + "..."} descripcion={blogData.desc + '...'} />
          })}
        </div>
      </div>
    </div>)
  }
}

export default BlogPage;
