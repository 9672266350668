import React from 'react';
import './App.css';
import {Link} from 'react-router-dom';
import Modal from './Modal.js';
import Opc from './funciones.js';
class Cotizar extends React.Component{
      state = {
            show: false
          };
          showModal = e => {
            this.setState({
              show: !this.state.show
            });
           
          };
          onClose = e => {
            this.props.onClose && this.props.onClose(e);
          };
render(){
return(
<div style={{margin:"2em 0em 2em 0em", position:'fixed', zIndex:'10001', top:'60%', right:'0'}}>
  <div className="flotante" style={{width:"fit-content", display:'flex', flexDirection:'row', alignItems:'center', justifyContent:'center'}}>    
    <a className="LinkButtonAzul" style={{textDecoration:'none'}}  onClick={e => {this.showModal();}}>{this.props.selectedLanguage === 'es' ? 'SOLICITAR COTIZACIÓN' : 'GET A QUOTE'}</a>
    <hr style={{width:'3em', height:'0', border:'2px solid #004c97', color:'#004c97'}}/>
  </div>  
<div>
<Modal
  onClose={this.showModal}
  show={this.state.show}
  closeStyle={{
    color:'white',
    border:'none',
    outline:'none'
  }}
  >
    <Opc val="11" selectedLanguage={this.props.selectedLanguage} />
  </Modal>
</div>
</div>

      )}}

export default Cotizar;

