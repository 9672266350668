import React from 'react';
import './App.css';
import { HashLink } from 'react-router-hash-link';

import leftArrow from './images/1x/galdisa_blog_left_arrow.png';
import rightArrow from './images/1x/galdisa_blog_right_arrow.png';

class Cuadricula extends React.Component{
    constructor(){
        super();
        this.state = {
            selectedIndex : 0
        }
        this.selectNext = this.selectNext.bind(this);
        this.selectPrev = this.selectPrev.bind(this);
        this.showSelection = this.showSelection.bind(this);
    }

    selectNext(){
        let newIndex = this.state.selectedIndex + 1;
        newIndex %= this.props.productos.length;
        this.setState({
            selectedIndex: newIndex
        });
    }
    selectPrev(){
        let newIndex = this.state.selectedIndex - 1;
        if(newIndex < 0){
            newIndex = this.props.productos.length - 1;
        }
        this.setState({
            selectedIndex: newIndex
        });
    }
    showSelection(){
        const baseIndex = this.state.selectedIndex;
        const size = this.props.productos.length;
        const selection= [];
        for(let i=0;i<this.props.productos.length;i++){
            const producto = this.props.productos[(baseIndex + i) % size];
            selection.push(
                <HashLink 
                    className="Cuadro"
                    key={producto.key.toString()}
                    to={producto.link}
                    scroll={el => { el.scrollIntoView(true); window.scrollBy(0, -120) }}
                    onClick={(e)=>{
                        if(this.props.onClick){
                            e.preventDefault();
                            this.props.onClick(producto);
                        } 
                    }}
                >
                    <img className="producto" src={producto.imagen} style={{width:'100%', height:'100%'}} alt={producto.nombre} />
                    <span>{producto.nombre}</span>
                </HashLink>
            )
        }
        return selection;
    }

    render(){
        return(           
            <div style={{width: '100%',  margin:"0 auto"}}>
                <div style={{width:'100%', display:'flex', flexDirection:'row', alignItems:'center', justifyContent:'space-between'}}>
                    {
                        this.props.productos.length > 4 && <div className="arrow" onClick={this.selectPrev}>
                            <img src={leftArrow} alt="izquierda" />
                        </div>
                    }
                    <div className="Cuadricula">
                        {
                            this.showSelection()
                        }
                    </div>
                    {
                        this.props.productos.length > 4 && <div className="arrow" onClick={this.selectNext}>
                            <img src={rightArrow} alt="izquierda" />
                        </div>
                    }
                </div>
                <div style={{paddingTop:"40px"}}>
                    {this.props.children}
                </div>
                <div>   

                </div>            
            </div>            
        )
}
}
export default Cuadricula;