import React from 'react';
import './App.css';

import {NavLink} from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

import galdisa_logo from './images/1x/galdisa_logo_color.png';
import langWorld from './images/1x/galdisa_lang_world.png';
import langEn from './images/1x/galdisa_lang_en.png';
import langEs from './images/1x/galdisa_lang_es.png';

import dict from './dict/MenuSup.js';

import { ReactComponent as IconFacebook} from './images/iconos/icon_facebook.svg';
import { ReactComponent as IconYoutube } from './images/iconos/icon_youtube.svg';
import { ReactComponent as IconLinkedIn } from './images/iconos/icon_linkedin.svg';

import Opc from './funciones';
import Modal from './Modal';
class MenuSup extends React.Component {
    state = {
        show: false,
        modalVal: "11",
      };
      showModal = (e, val) => {
        this.setState({
          show: !this.state.show,
          modalVal: val
        });
      };
      onClose = e => {
        this.props.onClose && this.props.onClose(e);
      };

    toggleMenu = function() {
        var x = window.document.getElementById("nav-menu");
        if (x.className === "Encabezado") {
            x.className += " responsive sticky";
        } else {
            x.className = "Encabezado";
        }
    }
    
    closeMenu = function(){
        var x = window.document.getElementById("nav-menu");
        x.className = "Encabezado";
    }
    
    componentDidUpdate(){
        console.log("updated");
        var x = window.document.getElementById("nav-menu");
        x.className = "Encabezado";
    }
    

    checkHeader(){
        let scrollPosition = Math.round(window.scrollY);
        if (scrollPosition > 0){
            document.querySelector('#nav-menu').classList.add('sticky');
        } else {
            document.querySelector('#nav-menu').classList.remove('sticky');
        }
                
    }

    componentDidMount(){
        window.addEventListener('scroll', this.checkHeader);
    }

    componentWillUnmount(){
        window.removeEventListener('scroll', this.checkHeader);
    }

render(){
        return(
            <div className="nav-sup">
                <div className="langSelector" style={{alignItems:'center'}}>
                    <div className="footer-social" style={{alignItems:'flex-end', justifyContent:'baseline'}}>
                        <a href="https://www.facebook.com/GaldisaMX/" rel="noopener noreferrer" target="_blank"><IconFacebook className="share_icon" style={{width:'1.5rem'}}/></a>
                        <a href="https://www.linkedin.com/company/galdisa/" rel="noopener noreferrer" target="_blank"><IconLinkedIn className="share_icon" style={{width:'1.5rem'}}/></a>
                       <a href=" https://www.youtube.com/channel/UCTXXTjXbQn2LtvMxrZImuVA/featured" rel="noopener noreferrer" target="_blank"><IconYoutube className="share_icon" style={{width:'1.5rem'}} /></a>
                    </div>

                    <div className="langItem" style={{marginRight:'0px'}}><img src={langWorld} alt="" /></div>
                    <div className="langItem" onClick={()=>{this.props.languageSelector(`en`)}} style={{marginLeft:'.5rem', fontWeight: this.props.selectedLanguage === `en` ? 800 : `normal`, cursor:`pointer`}}>ENG </div>
                    <div className="langItem" onClick={()=>{this.props.languageSelector(`es`)}} style={{fontWeight: this.props.selectedLanguage === `es` ? 800 : `normal`, cursor:`pointer`}}>ESP </div>
                </div>
                <div style={{display:'flex', flexDirection:'row'}}>
                    <div id="nav-menu" className="Encabezado" >
                        <NavLink className="Link" to="/" onClick={this.closeMenu}><img className="logo" src={galdisa_logo} alt="Logo Galdisa" /></NavLink>
                        <div className="dropdown">
                            <NavLink activeClassName="selected" className="Link" exact to="/" onClick={this.closeMenu}>HOME</NavLink>
                        </div>
                        <div className="dropdown">
                            <NavLink activeClassName="selected" className="Link" to="/aboutus" onClick={this.closeMenu}>{dict[this.props.selectedLanguage].aboutus}</NavLink>
                            <ul className="dropdown-content">
                                <li>
                                    <HashLink 
                                        to="/aboutus#somos"
                                        scroll={el => { el.scrollIntoView(true); window.scrollBy(0, -120) }}
                                    >
                                            {dict[this.props.selectedLanguage].somos}
                                    </HashLink>
                                </li>

                                <li>
                                    <HashLink 
                                        to="/aboutus#historia"
                                        scroll={el => { el.scrollIntoView(true); window.scrollBy(0, -120) }}
                                    >
                                            {dict[this.props.selectedLanguage].historia}
                                    </HashLink>
                                </li>
                                <li>
                                    <HashLink
                                        to="/aboutus#filosofia"
                                        scroll={el => { el.scrollIntoView(true); window.scrollBy(0, -120) }}
                                    >
                                        {dict[this.props.selectedLanguage].filosofia}
                                    </HashLink>
                                </li>
                                <li>
                                    <HashLink 
                                        to="/aboutus#proceso"
                                        scroll={el => { el.scrollIntoView(true); window.scrollBy(0, -120) }}
                                    >
                                        {dict[this.props.selectedLanguage].proceso}
                                    </HashLink>
                                </li>
                                <li>
                                    <HashLink 
                                        to="/aboutus#seguridad"
                                        scroll={el => { el.scrollIntoView(true); window.scrollBy(0, -120) }}
                                    >
                                        {dict[this.props.selectedLanguage].seguridad}
                                    </HashLink>
                                </li>
                                <li>
                                    <HashLink
                                        to="/aboutus#sustentabilidad"
                                        scroll={el => { el.scrollIntoView(true); window.scrollBy(0, -120) }}
                                    >
                                        {dict[this.props.selectedLanguage].sustentabilidad}
                                    </HashLink>
                                </li>
                                <li>
                                    <HashLink
                                    to="/aboutus#certifications"
                                    scroll={el => { el.scrollIntoView(true); window.scrollBy(0, -120) }}
                                >
                                    {dict[this.props.selectedLanguage].certificaciones}
                                </HashLink>
                            </li>
                            </ul>
                        </div>
                        <div className="dropdown">
                            <NavLink activeClassName="selected" className="Link" to="/products" onClick={this.closeMenu}>{dict[this.props.selectedLanguage].productos}</NavLink>
                            <ul className="dropdown-content">
                                <li>
                                    <HashLink 
                                        to="/products#natural"
                                        scroll={el => { el.scrollIntoView(true); window.scrollBy(0, -120) }}
                                    >
                                        {dict[this.props.selectedLanguage].natural}
                                    </HashLink>
                                </li>
                                <li>
                                    <HashLink 
                                        to="/products#procesados"
                                        scroll={el => { el.scrollIntoView(true); window.scrollBy(0, -120) }}
                                    >
                                        {dict[this.props.selectedLanguage].procesados}
                                    </HashLink>
                                </li>
                                <li>
                                    <HashLink 
                                        to="/products#botanas"
                                        scroll={el => { el.scrollIntoView(true); window.scrollBy(0, -120) }}    
                                    >
                                        {dict[this.props.selectedLanguage].botanas}
                                    </HashLink>
                                </li>
                                <li>
                                    <HashLink 
                                        to="/products#granel"
                                        scroll={el => { el.scrollIntoView(true); window.scrollBy(0, -120) }}
                                    >
                                        {dict[this.props.selectedLanguage].granel}
                                    </HashLink>
                                </li>
                                <li>
                                    <HashLink 
                                        to="/detailproducts"
                                        scroll={el => { el.scrollIntoView(true); window.scrollBy(0, -120) }}
                                    >
                                        {dict[this.props.selectedLanguage].adicional}
                                    </HashLink>
                                </li>
                            </ul>                    
                        </div>
                        <div className="dropdown">
                            <NavLink activeClassName="selected" className="Link" to="/services" onClick={this.closeMenu}>{dict[this.props.selectedLanguage].servicios}</NavLink>
                            <ul className="dropdown-content">
                                <li>
                                    <HashLink 
                                        to="/services#servicios"
                                        scroll={el => { el.scrollIntoView(true); window.scrollBy(0, -120) }}
                                    >
                                        {dict[this.props.selectedLanguage].nuestros}
                                    </HashLink>
                                </li>
                                <li>
                                    <HashLink
                                        to="/services#programa"
                                        scroll={el => { el.scrollIntoView(true); window.scrollBy(0, -120) }}
                                    >
                                        {dict[this.props.selectedLanguage].programa}
                                    </HashLink>
                                </li>
                                <li>
                                    <HashLink
                                        to="/services#ciclo-cultivo"
                                        scroll={el => { el.scrollIntoView(true); window.scrollBy(0, -120) }}
                                    >
                                        {dict[this.props.selectedLanguage].ciclo}
                                    </HashLink>
                                </li>
                                <li>
                                    <HashLink
                                        to="/services#comercializacion"
                                        scroll={el => { el.scrollIntoView(true); window.scrollBy(0, -120) }}
                                    >
                                        {dict[this.props.selectedLanguage].infraestructura}
                                    </HashLink>
                                </li>
                            </ul>
                        </div>
                        <div className="dropdown">
                            <NavLink activeClassName="selected" className="Link" to="/blog" onClick={this.closeMenu}>BLOG</NavLink> 
                        </div>
                        <div className="dropdown">
                            <NavLink 
                                activeClassName="selected" 
                                className="Link" 
                                to="/contact#cotizar" 
                                onClick={e => {e.preventDefault(); this.showModal(e, "10"); this.closeMenu();}}
                            >
                                {dict[this.props.selectedLanguage].contacto}
                            </NavLink>
                            <ul className="dropdown-content">
                                <li>
                                    <a 
                                        href={this.props.selectedLanguage === `es` ? "mailto:info@galdisa.com?subject=Contacto%20Galdisa" : "mailto:info@galdisa.com?subject=Contact%20Galdisa"}
                                    >
                                        {dict[this.props.selectedLanguage].contacto}
                                    </a>
                                </li>
                                <li>
                                    <HashLink
                                        to="/contact#cotizar"
                                        scroll={el => { el.scrollIntoView(true); window.scrollBy(0, -120) }}
                                        onClick={e => {e.preventDefault(); this.showModal(e,"11");}}
                                    >
                                        {dict[this.props.selectedLanguage].cotizacion}
                                    </HashLink>
                                </li>
                                <li>
                                    <a
                                        href={this.props.selectedLanguage === `es` ? "mailto:industrialreclutamiento@galdisa.com?subject=Solicitud%20Trabajo%20Galdisa" : "mailto:industrialreclutamiento@galdisa.com?subject=Employment%20application%20Galdisa"}
                                    >
                                        {dict[this.props.selectedLanguage].trabajo}
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <button className="icon" onClick={this.toggleMenu}>
                            <i className="fas fa-2x fa-bars"></i>
                    </button>
                </div>
                <div>
<Modal
  onClose={this.showModal}
  show={this.state.show}
  closeStyle={{
    color:'white',
    border:'none',
    outline:'none'
  }}
  >
    <Opc val={this.state.modalVal} selectedLanguage={this.props.selectedLanguage} />
  </Modal>
</div>
            </div>
        )
    }
}
export default MenuSup;

