import React from 'react';
import './App.css';

import leftArrow from './images/1x/galdisa_blog_left_arrow.png';
import rightArrow from './images/1x/galdisa_blog_right_arrow.png';

let timeout;
class TimeLine extends React.Component {

    constructor(props){
        super();
        this.state = {
            banners: props.banners,
            selectedBanner: props.selected || 0
        };

    }

    selectPrev(){
        let newSelection = this.state.selectedBanner - 1;
        if(newSelection < 0) newSelection = this.state.banners.length - 1;
        newSelection %= this.state.banners.length;
        return this.selectBanner(newSelection);
    }
    selectNext(){
        let newSelection = this.state.selectedBanner + 1;
        newSelection %= this.state.banners.length;
        return this.selectBanner(newSelection);
    }

    updateSlide(){
        let newSelection = this.state.selectedBanner + 1;
        newSelection %= this.state.banners.length;
        this.setState({
            selectedBanner: newSelection
        });
        timeout = setTimeout(()=>{
            this.updateSlide();
        }, 10000);
    }

    selectBanner(banner){
        clearTimeout(timeout);
        this.setState({
            selectedBanner: banner
        });
        timeout = setTimeout(()=>{
            this.updateSlide();
        }, 10000);
    }

    componentDidMount(){
        timeout = setTimeout(() => {
            this.updateSlide();
        }, 10000);
    }

    componentWillUnmount(){
        clearTimeout(timeout);
    }
    render(){
        let bolitas=<div className="timeline-selectors">
            {
                this.state.banners.map((banner, id)=>{
                    return <div key={"selector-"+id} onClick={()=>{this.selectBanner(id)}} className={"timeline-selector " + (this.state.selectedBanner === id ? "timeline-selector-active" : "" )}>
                        <span>{banner.year}</span>
                    </div>
                })
            }
        </div>    
        return(
            <div id={this.props.id} className="timeline" style={{width:'100%', display:'flex', flexDirection:'column', marginTop: '2rem'}} >
                <div className="timeline-banner" style={{width:'100%', height:'424px', display:'flex', flexDirection:'row'}}>
                    <div onClick={()=>{this.selectPrev()}} className="timeline-arrow" style={{cursor:'pointer', width:'10%', display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'center'}}>
                            <img alt="" style={{width:'50%'}} src={leftArrow}></img>
                    </div>
                    <div className="timeline-data" >
                        <div className="timeline-image" >
                            <img alt="Banner" src={this.state.banners[this.state.selectedBanner].imagen} />
                        </div>
                        <div className="timeline-post" >
                            <h2 className='texto-bold verde' style={{textAlign:'left', margin:'0'}}>{this.state.banners[this.state.selectedBanner].year}</h2>
                            <h2 className='texto-harabara verde' style={{textAlign:'left', margin:'0', fontSize:'3em'}}>{this.state.banners[this.state.selectedBanner].title}</h2>
                            <p style={{marginBottom:'auto', color:'#4d4d4d', fontSize:'1.25em', textAlign:'left'}} >{this.state.banners[this.state.selectedBanner].text}</p>
                            {this.state.banners[this.state.selectedBanner].logo && <img alt="Banner" style={{width:this.state.banners[this.state.selectedBanner].width, display:'flex', alignSelf:'flex-end'}} src={this.state.banners[this.state.selectedBanner].logo} />}
                        </div>
                    </div>
                    <div onClick={()=>{this.selectNext()}} className="timeline-arrow" style={{cursor:'pointer', width:'10%', display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'center'}}>
                        <img alt="" style={{width:'50%'}} src={rightArrow}></img>
                    </div>        
                </div>
                {bolitas}
            </div>

        )
    }
}
export default TimeLine;
