import React from 'react';
import './App.css';

import privacy from './data/privacy.js';
import SoloTexto from './SoloTexto';

class PrivacyPage extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      post: 'privacy',
      blogData: privacy['privacy'][this.props.selectedLanguage]
    }
  }


  componentDidUpdate(prevProps){
    const changes = {};
    if(prevProps.selectedLanguage !== this.props.selectedLanguage){
      changes.blogData = privacy['privacy'][this.props.selectedLanguage];
    }
    if(Object.keys(changes).length > 0){
      this.setState(changes);
    }
  }
  render() {
    return(    
      <div className="Contenedor" style={{margin:'0px auto', marginTop:'140px', marginBottom:'3rem'}}>
        <SoloTexto style={{padding:'0 2rem', textTransform:'uppercase',fontSize:'2rem',width:'60%' }} color='verde' alineacion="center" titulo={this.state.blogData.title}/>
        <div id="blog" className="">
          <div style={{display:'flex', flexDirection:'column', textAlign:'justify', width:'90%', color:'#4d4d4d', margin:'0 auto'}}>
            {this.state.blogData.pars.map((par,id)=>{
              return <div key={"blog-page-par" + id} className="blog-page-par" >
                {par.gallery && <div className="blog-page-par-gallery">{
                  par.gallery.map((image, idx)=>{return <img key={'blog-page-gallery-' + idx} src={image.src} alt={image.alt} />} )}
                </div>}
                {par.title && <span className="blog-page-par-title">{par.title}</span>}
                {par.text && <span className={"blog-page-par-text " + par.className ? par.className:"" }>{par.text}</span>}
                {par.list && <ul>{par.list.map((text,idx)=>{return <li key={'blog-page-par-list-' + idx}>{text}</li>})}</ul>}
              </div>
            })}
            {this.state.blogData.refs.length > 0 &&
              <div key="blog-page-reference" className="blog-page-par"><span className="blog-page-par-title">Referencias:</span><ul>
                {this.state.blogData.refs.map((ref, id)=>{
                  return <li key={"blog-page-par-ref"+id}>
                    <span className="blog-page-reference">{ref.text}</span>
                    {ref.link && <div><span className="blog-page-par-text">Recuperado de: </span><a className="blog-page-ref-link" href={ref.link} rel="noopener noreferrer" target="_blank">{ref.link}</a></div>}    
                    </li>
                })
              }
              </ul></div>
            }
            {this.state.blogData.author &&
              <div className="blog-page-author">
                <span className={"blog-page-par-text texto-s"}>{this.state.blogData.author.brief}</span>
                {this.state.blogData.author.contacts.map((contact)=>{
                  return <div className="texto-s" style={{margin:'0.5em 0'}}><span>{contact.social}:</span><a href={contact.link} rel="noopener noreferrer" target="_blank">{contact.text}</a></div>
                })}
              </div>
            }
          </div>
      </div>
    </div>)
  }
}

export default PrivacyPage;
